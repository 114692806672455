// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.png";
import logo from "./assets/logo.png";
// Profile Image
import profile from "./assets/profile.png";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import sass from "./assets/techstack/sass.png";
import js from "./assets/techstack/js.png";
import react from "./assets/techstack/react.png";
import redux from "./assets/techstack/redux.png";
import tailwind from "./assets/techstack/tailwind.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import figma from "./assets/techstack/figma.png";
// Porject Images
import projectImage1 from "./assets/projects/project1.jpg";

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Zekriansyah Ramadhan",
  tagline: "I'm a Tech Enthusiast.",
  img: profile,
  about: `A Technical Support professional at a web hosting company in Indonesia, I am also interested in the field of System Administration. I enjoy learning new things and am full of curiosity.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/in/zekriansyah",
  github: "https://github.com/zexry619",
  twitter: "https://x.com/zekrisme",
  instagram: "https://www.instagram.com/zekrisme",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Techical Specialist",
    Company: `DomaiNesia`,
    Location: "Yogyakarta",
    Type: "Full Time",
    Duration: "Sep 2022 - Present",
  },
  {
    Position: "Internship",
    Company: `PT. Telkom Akses`,
    Location: "Yogyakarta",
    Type: "Internship",
    Duration: "Sep 2019 - Dec 2019",
  },
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Student",
    Company: "Amikom University Yogyakarta",
    Location: "Yogyakarta",
    Type: "Full Time",
    Duration: "2017 - 2022",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  react: react,
  redux: redux,
  sass: sass,
  tailwind: tailwind,
  bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "Just A Sample",
    image: projectImage1,
    description: `If there's a project I can work on, feel free to contact me :D`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
];

// Enter your Contact Details here
export const contactDetails = {
  email: "zekri@zekri.my.id",
  phone: "+6285156xxxxxx",
};
